import { useParams, useNavigate } from "react-router-dom"
import { URL } from "../utils/utils"
import { useEffect, useState } from "react"
import classes from "../stili/PaginaDettaglioProposta.module.css"

const PaginaDettaglioProposta = () => {

    const user_id = window.localStorage.getItem('user_id')
    const user_token = window.localStorage.getItem('user_token')
    const user_amministratore = Number(window.localStorage.getItem('user_amministratore'))

    const [proposte, setProposte] = useState([])
    const [dettagli_spazio, setDettagliSpazio] = useState({})
    const [reload, setReload] = useState(false)

    const navigate = useNavigate()

    const params = useParams()
    const id_spazio_proposte = params.idProposta

    const fetch_dettagli_spazio = async () => {
        const data = new FormData()
        data.append('id', user_id)
        data.append('token', user_token)
        data.append('spazio', id_spazio_proposte)
        return await fetch(
            URL + '/dettagli_spazio.php', {
                method: 'POST',
                body: data
            }
        ).then(res => {
            return res.json()
        })
    }

    const fetch_proposte = async () => {
        const data = new FormData()
        data.append('id', user_id)
        data.append('token', user_token)
        data.append('spazio', id_spazio_proposte)
        return await fetch(
            URL + '/proposte.php', {
                method: 'POST',
                body: data
            }
        ).then(res => {
            return res.json()
        })
    }

    const fetch_invia_proposta = async (titolo, proposta) => {
        const data = new FormData()
        data.append('id', user_id)
        data.append('token', user_token)
        data.append('titolo', titolo)
        data.append('proposta', proposta)
        data.append('spazio', id_spazio_proposte)
        return await fetch(
            URL + '/invia_proposta.php', {
                method: 'POST',
                body: data
            }
        ).then(res => {
            return res.json()
        })
    }

    const fetch_vota_proposta = async (id_proposta, voto) => {
        const data = new FormData()
        data.append('id', user_id)
        data.append('token', user_token)
        data.append('voto', voto)
        data.append('id_proposta', id_proposta)
        data.append('spazio', id_spazio_proposte)
        return await fetch(
            URL + '/vota_proposta.php', {
                method: 'POST',
                body: data
            }
        ).then(res => {
            return res.json()
        })
    }

    const on_invia = () => {
        const titolo = document.querySelector('#titolo').value
        const proposta = document.querySelector('#proposta').value
        if (titolo && proposta && dettagli_spazio.is_aperto) {
            fetch_invia_proposta(titolo, proposta).then(risposta => {
                if (risposta.successo) {
                    setReload(!reload)
                }
                document.querySelector('#titolo').value = ''
                document.querySelector('#proposta').value = ''
            })
        }
    }

    const on_vote = (id, up) => {
        const voto = up ? 1 : 0
        fetch_vota_proposta(id, voto).then(risposta => {
            if (risposta.successo) {
                setReload(!reload)
            }
        })
    }

    const on_modifica = () => {
        if (user_amministratore) {
            navigate(`/proposte/modifica/${id_spazio_proposte}`)
        }
    }

    const map_proposte = (el) => {
        return (
            <li key={el.id}>
                <div className={`${classes.card}`}>
                    <div className={`${classes.card_title}`}>
                        {el.titolo}
                    </div>
                    <div className={`${classes.card_content}`}>
                        <div>
                            {el.contenuto}
                        </div>
                        <div className={`mt-1 flex align-center justify-evenly`}>
                            <div className={`text-center`}>
                                {
                                    dettagli_spazio.is_aperto ?
                                    <button onClick={on_vote.bind(this, el.id, true)} className={`${classes.buttons} size-medium pointer`}>
                                        {
                                            el.voto_utente > 0 ?
                                            <i className="fa-solid fa-thumbs-up voted"></i> :
                                            <i className="fa-regular fa-thumbs-up"></i>
                                        }
                                    </button> :
                                    <div className={`size-medium`}>
                                        {
                                            el.voto_utente > 0 ?
                                            <i className="fa-solid fa-thumbs-up voted"></i> :
                                            <i className="fa-regular fa-thumbs-up"></i>
                                        }
                                    </div>
                                }
                                <div>{el.upvotes}</div>
                            </div>
                            <div className={`text-center`}>
                                {
                                    dettagli_spazio.is_aperto ?
                                    <button onClick={on_vote.bind(this, el.id, false)} className={`${classes.buttons} size-medium pointer`}>
                                        {
                                            el.voto_utente < 0 ?
                                            <i className="fa-solid fa-thumbs-down voted"></i> :
                                            <i className="fa-regular fa-thumbs-down"></i>
                                        }
                                    </button> :
                                    <div className={`size-medium`}>
                                        {
                                            el.voto_utente < 0 ?
                                            <i className="fa-solid fa-thumbs-down voted"></i> :
                                            <i className="fa-regular fa-thumbs-down"></i>
                                        }
                                    </div>
                                }
                                <div>{el.downvotes}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        )
    }

    useEffect(() => {
        fetch_dettagli_spazio().then(risposta => {
            if (risposta.successo) {
                setDettagliSpazio(risposta)
            }
        })
        fetch_proposte().then(risposta => {
            if (risposta.successo) {
                setProposte(risposta.array)
            }
        })
    }, [reload])

    return (
        <div className={`${classes.container} max-width`}>
            <div className={`${classes.header_container}`}>
                <div className={`${classes.title_container}`}>
                    <div className={`text-center size-big`}>{dettagli_spazio.titolo}</div>
                    <div className={`mt-2 size-small ${classes.descrizione}`}>{dettagli_spazio.descrizione}</div>
                </div>
                <div className={`${classes.status_container} text-center`}>
                    <div>{dettagli_spazio.is_aperto ? 'Aperto' : 'Chiuso'}</div>
                    <div className={`${classes.categoria}`}>{dettagli_spazio.categoria}</div>
                    {
                        user_amministratore && dettagli_spazio.is_aperto ?
                        <button onClick={on_modifica} className={`${classes.stato} btn btn-primary btn-regular`}>Modifica</button> : []
                    }
                </div>
            </div>
            <div className={`mt-3 mb-2 ${dettagli_spazio.is_aperto ? classes.wrapper : []}`}>
                <div>
                    {
                        proposte.length > 0 ?
                        <ul className={`${classes.cards_container}`}>
                            {
                                proposte.map(map_proposte)
                            }
                        </ul> : 'Nessuna proposta da visualizzare'
                    }
                </div>
                {
                    dettagli_spazio.is_aperto ?
                    <div className={`${classes.inputs_container}`}>
                        <label htmlFor={`titolo`}>Titolo:</label>
                        <input type={`text`} id={`titolo`} className={`${classes.input} block mt-0-5 max-width`}/>
                        <label htmlFor={`proposta`} className={`mt-1 block`}>Proposta:</label>
                        <textarea id={`proposta`} className={`${classes.input} block mt-0-5 max-width`} rows={3}></textarea>
                        <button onClick={on_invia} className={`mt-4 btn btn-primary btn-regular max-width`}>Invia proposta</button>
                    </div> : []
                }
            </div>
        </div>
    )
}

export default PaginaDettaglioProposta