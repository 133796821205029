import { Link } from "react-router-dom"
import classes from "../stili/ComponentePannello.module.css"

const ComponentePannello = (props) => {

    const on_mostra_collapse = (e) => {
        const collapse = document.querySelector('#collapse')
        const target = e.target
        if (collapse.style.maxHeight) {
            collapse.style.maxHeight = null
            target.classList.remove(`${classes.rotated}`)
        } else {
            collapse.style.maxHeight = collapse.scrollHeight + 'px'
            target.classList.add(`${classes.rotated}`)
        }
    }

    const on_link_click = () => {
        const collapse = document.querySelector('#collapse')
        const target = document.querySelector('#title')
        collapse.style.maxHeight = null
        target.classList.remove(`${classes.rotated}`)
    }

    return (
        <div className={`${classes.container} color-secondary`}>
            <div className={`${classes.title} size-medium text-center`} onClick={on_mostra_collapse} id={`title`}>
                Pannello
            </div>
            <nav className={`${classes.collapse} ${classes.max_height}`} id={`collapse`}>
                <ul className={`${classes.list_container}`}>
                    <li className={`${classes.link} mb-2`}>
                        <Link to={`/`} onClick={on_link_click}>
                            <span className={`mr-1-5 size-semi-regular`}>
                                <i className="fa-regular fa-folder-open"></i>
                            </span>
                            Inchieste
                        </Link>
                    </li>
                    <li className={`${classes.link} mb-2`}>
                        <Link to={`/calendario`} onClick={on_link_click}>
                            <span className={`mr-1-5 size-semi-regular`}>
                                <i className="fa-regular fa-calendar-days"></i>
                            </span>
                            Calendario
                        </Link>
                    </li>
                    <li className={`${classes.link} mb-2`}>
                        <Link to={`/proposte`} onClick={on_link_click}>
                            <span className={`mr-1-5 size-semi-regular`}>
                                <i className="fa-regular fa-hand"></i>
                            </span>
                            Proposte
                        </Link>
                    </li>
                    <li className={`${classes.link} mb-2`}>
                        <Link to={`/luoghi`} onClick={on_link_click}>
                            <span className={`mr-1-5 size-semi-regular`}>
                                <i className="fa-solid fa-location-dot"></i>
                            </span>
                            Luoghi
                        </Link>
                    </li>
                    <li className={`${classes.link} mb-2`}>
                        <Link to={`/file`} onClick={on_link_click}>
                            <span className={`mr-1-5 size-semi-regular`}>
                                <i className="fa-regular fa-file-invoice"></i>
                            </span>
                            File
                        </Link>
                    </li>
                    <li className={`${classes.link} mb-2`}>
                        <Link to={`/notifiche`} onClick={on_link_click}>
                            <span className={`mr-1-5 size-semi-regular relative`}>
                                <i className="fa-regular fa-envelope"></i>
                                {
                                    props.notifiche > 0 ?
                                    <span className={`${classes.badge}`}>
                                        {props.notifiche}
                                    </span> : []
                                }
                            </span>
                            <span>
                                Notifiche
                            </span>
                        </Link>
                    </li>
                    <li className={`${classes.link} mb-2`}>
                        <Link to={`/account`} onClick={on_link_click}>
                            <span className={`mr-1-5 size-semi-regular relative`}>
                                <i className="fa-solid fa-user"></i>
                                {
                                    props.nuovi_utenti > 0 ?
                                    <span className={`${classes.badge}`}>
                                        {props.nuovi_utenti}
                                    </span> : []
                                }
                            </span>
                            <span>
                                Account
                            </span>
                        </Link>
                    </li>
                    <li className={`${classes.link}`}>
                        <Link to={`/cestino`} onClick={on_link_click}>
                            <span className={`mr-1-5 size-semi-regular`}>
                                <i className="fa-solid fa-trash-can"></i>
                            </span>
                            Cestino
                        </Link>
                    </li>
                </ul>
            </nav>
        </div>
    )
}

export default ComponentePannello