import classes from "../stili/ComponenteHeader.module.css"

const ComponenteHeader = () => {
    return (
        <header className={`${classes.header} text-center size-bigger color-primary`}>
            Clan dell'Alpe
        </header>
    )
}

export default ComponenteHeader