import { useEffect, useState } from "react"
import { URL, FILES_URL } from "../utils/utils"
import classes from "../stili/PaginaModificaAccount.module.css"
import user_img from "../imgs/user.jpg"

const PaginaModificaAccount = () => {

    const user_id = window.localStorage.getItem('user_id')
    const user_token = window.localStorage.getItem('user_token')

    const ruoli_utente = [
        'Ospite',
        'Membro Temporaneo',
        'Membro',
        'Amministratore'
    ]

    const [dettagli_account, setDettagliAccount] = useState({})
    const [modifica, setModifica] = useState(false)
    const [reload, setReload] = useState(false)

    const fetch_account_info = async () => {
        const data = new FormData()
        data.append('id', user_id)
        data.append('token', user_token)
        return await fetch(
            URL + '/dettagli_account.php', {
                method: 'POST',
                body: data
            }
        ).then(res => {
            return res.json()
        })
    }

    const fetch_cambia_password = async (vecchia_password, nuova_password) => {
        const data = new FormData()
        data.append('id', user_id)
        data.append('token', user_token)
        data.append('nuova_password', nuova_password)
        data.append('vecchia_password', vecchia_password)
        return await fetch(
            URL + '/cambia_password.php', {
                method: 'POST',
                body: data
            }
        ).then(res => {
            return res.json()
        })
    }

    const fetch_modifica_account = async (form_data) => {
        return await fetch(
            URL + '/modifica_account.php', {
                method: 'POST',
                body:  form_data
            }
        ).then(res => {
            return res.json()
        })
    }

    const on_logout = () => {
        window.localStorage.clear()
        window.location.replace('/')
    }

    const on_modifica = () => {
        setModifica(true)
    }

    const on_cancella_modifiche = () => {
        setModifica(false)
    }

    const on_conferma_modifiche = () => {
        const nome = document.querySelector('#nome')
        const cognome = document.querySelector('#cognome')
        const username = document.querySelector('#username')
        if (nome && cognome && username) {
            const form = document.querySelector('form')
            const form_data = new FormData(form)
            form_data.append('id', user_id)
            form_data.append('token', user_token)
            fetch_modifica_account(form_data).then(risposta => {
                if (risposta.successo) {
                    setReload(!reload)
                    setModifica(false)
                }
            })
        }
    }

    const on_cambia_password = () => {
        const vecchia_password = document.querySelector('#vecchia_password').value
        const nuova_password = document.querySelector('#nuova_password').value
        const conferma_password = document.querySelector('#conferma_password').value
        if (vecchia_password && nuova_password && conferma_password) {
            if (nuova_password === conferma_password) {
                fetch_cambia_password(vecchia_password, nuova_password).then(risposta => {
                    if (risposta.successo) {
                        window.localStorage.setItem('user_token', risposta.token)
                        window.location.reload()
                    }
                })
            }
        }
    }

    const on_file_input_change = (e) => {
        if (e.target.files.length > 0) {
            const file_name = e.target.files[0].name
            e.target.closest('label').querySelector('#file_content').innerText = file_name
        } else {
            e.target.closest('label').querySelector('#file_content').innerText = 'Scegli un file'
        }
    }

    const mostra_dati = () => {
        return (
            <div className={`${classes.wrapper} mt-3`}>
                <div className={`${classes.dati_container}`}>
                    <div>Nome:</div>
                    <div>{dettagli_account.nome}</div>
                    <div>Cognome:</div>
                    <div>{dettagli_account.cognome}</div>
                    <div>Soprannome:</div>
                    <div>{dettagli_account.soprannome ? dettagli_account.soprannome : 'N/D'}</div>
                    <div>Username:</div>
                    <div>{dettagli_account.username}</div>
                    <div>Usa soprannome:</div>
                    <div>{dettagli_account.usa_soprannome ? 'Sì' : 'No'}</div>
                </div>
                <button onClick={on_modifica} className={`mt-3 btn btn-primary btn-wide`}>Modifica</button>
            </div>
        )
    }

    const mostra_modifica_dati = () => {
        return (
            <div className={`${classes.wrapper} mt-3`}>
                <form className={`${classes.dati_container}`}>
                    <label htmlFor={`nome`}>Nome:</label>
                    <input type={`text`} id={`nome`} name={`nome`} className={`${classes.input}`}
                    defaultValue={dettagli_account.nome} autoComplete={`off`} />
                    <label htmlFor={`cognome`} >Cognome:</label>
                    <input type={`text`} id={`cognome`} name={`cognome`} className={`${classes.input}`}
                    defaultValue={dettagli_account.cognome} autoComplete={`off`} />
                    <label htmlFor={`soprannome`} >Soprannome:</label>
                    <input type={`text`} id={`soprannome`} name={`soprannome`} className={`${classes.input}`}
                    defaultValue={dettagli_account.soprannome} autoComplete={`off`} />
                    <label htmlFor={`usa_soprannome`} className={`pointer`}>Usa soprannome:</label>
                    <div className={`relative`}>
                        <input type={`checkbox`} id={`usa_soprannome`} defaultChecked={dettagli_account.usa_soprannome}
                        name={`usa_soprannome`} className={`${classes.checkbox} pointer`} />
                    </div>
                    <label htmlFor={`username`} >Username:</label>
                    <input type={`text`} id={`username`} name={`username`} className={`${classes.input}`}
                    defaultValue={dettagli_account.username} autoComplete={`off`} />
                    <div>Immagine Profilo:</div>
                    <label htmlFor={`immagine`} className={`${classes.input}`}>
                        <span className={`size-lighter block`} id={`file_content`}>Scegli un file</span>
                        <input type={`file`} id={`immagine`} onChange={on_file_input_change}
                        accept={`image/*`} name={`profilo`} className={`${classes.file_input}`}/>
                    </label>
                    <label htmlFor={`rimuovi_immagine`} className={`pointer`}>Rimuovi immagine:</label>
                    <div className={`relative`}>
                        <input type={`checkbox`} id={`rimuovi_immagine`} name={`rimuovi_immagine`} className={`${classes.checkbox} pointer`} />
                    </div>
                </form>
                <div className={`mt-3`}>
                    <button onClick={on_cancella_modifiche} className={`btn btn-secondary btn-wide`}>Cancella</button>
                    <button onClick={on_conferma_modifiche} className={`ml-3 btn btn-primary btn-wide`}>Salva</button>
                </div>
            </div>
        )
    }

    useEffect(() => {
        fetch_account_info().then(risposta => {
            if (risposta.successo) {
                setDettagliAccount(risposta)
            }
        })
    }, [reload])

    return (
        <div className={`${classes.container} max-width`}>
            <div className={`${classes.info_container}`}>
                <div className={`flex align-center`}>
                    <div className={`${classes.image_container}`}>
                        <img src={dettagli_account.immagine ? `${FILES_URL}${dettagli_account.immagine}` : user_img} alt={`profilo`}
                        className={`${classes.user_image}`}/>
                    </div>
                    <div className={`size-big color-primary ml-2`}>
                        {
                            dettagli_account.usa_soprannome ?
                            dettagli_account.soprannome : `${dettagli_account.nome} ${dettagli_account.cognome}`
                        }
                    </div>
                </div>
                <div className={`flex align-center justify-evenly ${classes.stato_container}`}>
                    <div className={`${classes.stato_inchiesta}`}>{ruoli_utente[dettagli_account.tipo_utente]}</div>
                    <div className={`${classes.logout}`}>
                        <button className={`btn btn-primary`} onClick={on_logout}>
                            Logout
                        </button>
                    </div>
                </div>
            </div>
            {
                modifica ? mostra_modifica_dati() : mostra_dati()
            }
            <div className={`${classes.wrapper} mt-4 pb-2`}>
                <div>Cambia password</div>
                <div className={`${classes.dati_container} mt-2`}>
                    <label htmlFor={`vecchia_password`}>Vecchia password:</label>
                    <input type={`password`} id={`vecchia_password`} className={`${classes.input}`}/>
                    <label htmlFor={`nuova_password`}>Nuova password:</label>
                    <input type={`password`} id={`nuova_password`} className={`${classes.input}`}/>
                    <label htmlFor={`conferma_password`}>Conferma nuova password:</label>
                    <input type={`password`} id={`conferma_password`} className={`${classes.input}`}/>
                </div>
                <button onClick={on_cambia_password} className={`mt-3 btn btn-primary btn-wide`}>Conferma</button>
            </div>
        </div>
    )
}

export default PaginaModificaAccount