import { useEffect, useState } from "react"
import { URL, FILES_URL } from "../utils/utils"
import classes from "../stili/PaginaFile.module.css"
import ext_csv from "../imgs/csv.png"
import ext_doc from "../imgs/doc.png"
import ext_docx from "../imgs/docx.png"
import ext_file from "../imgs/file.png"
import ext_pdf from "../imgs/pdf.png"
import ext_ppt from "../imgs/ppt.png"
import ext_pptx from "../imgs/pptx.png"
import ext_txt from "../imgs/txt.png"
import ext_xls from "../imgs/xls.png"
import ext_xlsx from "../imgs/xlsx.png"

const PaginaFile = () => {

    const user_id = window.localStorage.getItem('user_id')
    const user_token = window.localStorage.getItem('user_token')
    const user_amministratore = Number(window.localStorage.getItem('user_amministratore'))
    const is_mobile = (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0)) ||
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Windows Phone|Opera Mini/i.test(navigator.userAgent)

    const [files, setFiles] = useState([])
    const [file_selezionati, setFileSelezionati] = useState([])
    const [reload, setReload] = useState(false)

    const get_file_image = (ext) => {
        switch (ext) {
            case 'csv':
                return ext_csv
            case 'doc':
                return ext_doc
            case 'docx':
                return ext_docx
            case 'pdf':
                return ext_pdf
            case 'ppt':
                return ext_ppt
            case 'pptx':
                return ext_pptx
            case 'txt':
                return ext_txt
            case 'xls':
                return ext_xls
            case 'xlsx':
                return ext_xlsx
            default:
                return ext_file
        }
    }

    const fetch_files = async () => {
        const data = new FormData()
        data.append('id', user_id)
        data.append('token', user_token)
        return await fetch(
            URL + '/files.php', {
                method: 'POST',
                body: data
            }
        ).then(res => {
            return res.json()
        })
    }

    const fetch_carica_file = async (form_data) => {
        return await fetch(
            URL + '/aggiungi_file_pubblico.php', {
                method: 'POST',
                body:  form_data
            }
        ).then(res => {
            return res.json()
        })
    }

    const fetch_elimina_file = async () => {
        const data = new FormData()
        data.append('id', user_id)
        data.append('token', user_token)
        data.append('files', JSON.stringify(file_selezionati))
        return await fetch(
            URL + '/elimina_file_pubblico.php', {
                method: 'POST',
                body: data
            }
        ).then(res => {
            return res.json()
        })
    }

    const on_elimina = () => {
        if (file_selezionati.length > 0 && user_amministratore) {
            fetch_elimina_file().then(risposta => {
                if (risposta.successo) {
                    setFileSelezionati([])
                    setReload(!reload)
                }
            })
        }
    }

    const on_carica = () => {
        const nome = document.querySelector('#nome').value
        if (nome && document.querySelector('#file').files.length) {
            const form = document.querySelector('form')
            const form_data = new FormData(form)
            form_data.append('nome', nome)
            form_data.append('id', user_id)
            form_data.append('token', user_token)
            fetch_carica_file(form_data).then(risposta => {
                if (risposta.successo) {
                    document.querySelector('#nome').value = ''
                    form.reset()
                    setReload(!reload)
                }
            })
        }
    }

    const on_file_input_change = (e) => {
        if (e.target.files.length > 0) {
            const file_name = e.target.files[0].name
            e.target.closest('label').querySelector('#file_content').innerText = file_name
        } else {
            e.target.closest('label').querySelector('#file_content').innerText = 'Scegli un file'
        }
    }

    const on_file_selezionato = (id, e) => {
        const index = file_selezionati.indexOf(id)
        if (index >= 0) {
            if (!e.target.checked) {
                const copy_file_selezionati = [...file_selezionati]
                copy_file_selezionati.splice(index, 1)
                setFileSelezionati(copy_file_selezionati)
            }
        } else {
            if (e.target.checked) {
                const copy_file_selezionati = [...file_selezionati]
                copy_file_selezionati.push(id)
                setFileSelezionati(copy_file_selezionati)
            }
        }
    }

    const map_files = (el) => {
        const file_image = get_file_image(el.nome.split('.')[el.nome.split('.').length - 1])
        return (
            <li key={el.id} className={`relative`}>
                {
                    user_amministratore ?
                    <input type={`checkbox`} id={`chk${el.id}`} className={`${classes.card_checkbox}`}
                    onChange={on_file_selezionato.bind(this, el.id)} />: []
                }
                <div className={`${classes.card} pt-1 ${is_mobile ? classes.mobile : []}`}>
                    <label htmlFor={`chk${el.id}`}>
                        <div className={`${classes.card_image_container} text-center py-0-5`}>
                            <img src={file_image} className={`${classes.card_image}`} alt={`file`} />
                        </div>
                        <div className={`${classes.card_title_container} ${is_mobile ? classes.mobile : []} py-0-3 text-center px-2`}>
                            {el.nome}
                        </div>
                    </label>
                    <a href={`${FILES_URL}${el.percorso}`} download={true}
                    target={`${el.nome.split('.')[el.nome.split('.').length - 1].toLowerCase() === 'pdf' ? '_blank' : '_self'}`}
                    className={`${classes.download_container} ${is_mobile ? classes.mobile : []} px-1 py-0-5 text-center max-width block`}>
                        Scarica <i className="fa-solid fa-file-arrow-down ml-0-5"></i>
                    </a>
                </div>
            </li>
        )
    }

    useEffect(() => {
        fetch_files().then(risposta => {
            if (risposta.successo) {
                setFiles(risposta.array)
            }
        })
    }, [reload])

    return (
        <div className={`max-width ${user_amministratore ? [] : classes.non_admin} ${classes.container} py-2 ${user_amministratore ? classes.wrapper : []} mb-3`}>
            <div>
                {
                    files.length > 0 ?
                    <ul className={`${classes.cards_container} mx-auto`}>
                        {
                            files.map(map_files)
                        }
                    </ul> : 'Nessun file'
                }
            </div>
            {
                user_amministratore ?
                <div className={`${classes.form_container}`}>
                    <div className={`text-center size-big mb-2`}>Nuovo file</div>
                    <hr />
                    <form className={`mt-3`}>
                        File:
                        <label htmlFor={`file`} className={`${classes.input} mt-0-5 block`}>
                            <span id={`file_content`} className={`size-lighter block`}>Scegli un file</span>
                            <input type={`file`} accept={`.pdf, .doc, .docx, .xlsx, .txt, .pptx, .ppt, .csv, .xls`} onChange={on_file_input_change}
                            id={`file`} name={`file`} className={`${classes.file_input}`} />
                        </label>
                    </form>
                    <label htmlFor={`nome`} className={`mt-2 block`}>Nome:</label>
                    <input type={`text`} id={`nome`} className={`${classes.input} mt-0-5`} />
                    <button onClick={on_carica} className={`mt-4 btn btn-wide btn-primary max-width`}>Carica file</button>
                    <button onClick={on_elimina} className={`btn btn-wide ${file_selezionati.length > 0 ? 'btn-primary' : 'btn-disabled'} max-width mt-3`}>
                        Elimina file
                    </button>
                </div> : []
            }
        </div>
    )
}

export default PaginaFile