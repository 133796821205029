import { useEffect, useState } from "react"
import { URL } from "../utils/utils"
import classes from "../stili/PaginaCalendario.module.css"

const PaginaCalendario = (props) => {

    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ]
    const mesi = [
        'Gennaio',
        'Febbraio',
        'Marzo',
        'Aprile',
        'Maggio',
        'Giugno',
        'Luglio',
        'Agosto',
        'Settembre',
        'Ottobre',
        'Novembre',
        'Dicembre'
    ]
    const current_time = new Date()
    const user_id = window.localStorage.getItem('user_id')
    const user_token = window.localStorage.getItem('user_token')
    const amministratore = Number(window.localStorage.getItem('user_amministratore'))

    const [current_month, setCurrentMonth] = useState(current_time.getMonth())
    const [current_year, setCurrentYear] = useState(current_time.getFullYear())
    const [current_month_object, setCurrentMonthObject] = useState(null)
    const [reload, setReload] = useState(false)
    const [eventi, setEventi] = useState([])

    const fetch_eventi = async (primo, ultimo) => {
        const data = new FormData()
        data.append('id', user_id)
        data.append('token', user_token)
        data.append('primo', primo)
        data.append('ultimo', ultimo)
        return await fetch(
            URL + '/eventi.php', {
                method: 'POST',
                body:  data
            }
        ).then(res => {
            return res.json()
        })
    }

    const fetch_aggiungi_evento = async (titolo, inizio, fine, scadenza) => {
        const data = new FormData()
        data.append('id', user_id)
        data.append('token', user_token)
        data.append('titolo', titolo)
        data.append('inizio', inizio)
        data.append('scadenza', scadenza)
        if (!scadenza) data.append('fine', fine)
        return await fetch(
            URL + '/aggiungi_evento.php', {
                method: 'POST',
                body: data
            }
        ).then(res => {
            return res.json()
        })
    }

    const on_precedente = () => {
        setCurrentYear(current_month !== 0 ? current_year : current_year - 1)
        setCurrentMonth((current_month + 11) % 12)
    }

    const on_successivo = () => {
        setCurrentYear(current_month !== 11 ? current_year : current_year + 1)
        setCurrentMonth((current_month + 1) % 12)
    }

    const on_aggiungi_evento = () => {
        const titolo = document.querySelector('#titolo').value
        const inizio = document.querySelector('#inizio').value
        const fine = document.querySelector('#fine').value
        let scadenza = 0
        if (document.querySelector('#scadenza')) {
            scadenza = document.querySelector('#scadenza').checked ? 1 : 0
        }
        if (titolo && inizio && ((fine && inizio < fine) || scadenza)) {
            let testo_alert = 'Sto aggiungendo un nuovo evento. Potrebbe volerci del tempo...'
            if (scadenza) testo_alert = 'Sto aggiungendo una nuova scadenza. Potrebbe volerci del tempo...'
            const key = props.add_alert(testo_alert, 1)
            fetch_aggiungi_evento(titolo, inizio, fine, scadenza).then(risposta => {
                if (risposta.successo) {
                    let testo_alert_risposta = 'Nuovo evento aggiunto correttamente.'
                    if (scadenza) testo_alert_risposta = 'Nuova scadenza aggiunta correttamente.'
                    props.add_alert(testo_alert_risposta, 2, true, key)
                    document.querySelector('#titolo').value = ''
                    document.querySelector('#inizio').value = ''
                    document.querySelector('#fine').value = ''
                    if (document.querySelector('#scadenza')) {
                        document.querySelector('#scadenza').checked = false
                    }
                    setReload(!reload)
                }
            })
        }
    }

    const map_eventi = (el, index) => {
        return (
            <div key={`e${index}`} className={`${classes.evento} mt-0-5 px-0-5 ${el.scadenza ? classes.scadenza : []}`}>
                {el.titolo}
            </div>
        )
    }

    const map_giorno = (first_week, last_week, el, index) => {
        let element_classes = `${classes.day} ${classes.regular_border}`
        if (first_week) {
            element_classes += ` ${classes.border_double_top}`
        }
        if (last_week) {
            element_classes += ` ${classes.border_double_bottom}`
        }
        if (index === 0) {
            element_classes += ` ${classes.border_double_left}`
        }
        if (index === 6) {
            element_classes += ` ${classes.border_double_right}`
        }
        if (!el.is_current_month) {
            element_classes += ` ${classes.border_grey_color} ${classes.grey_color}`
        }
        return (
            <div key={`d${index}`} className={element_classes}>
                {el.date < 10 ? '0' + el.date : el.date}
                {
                    el.eventi.map(map_eventi)
                }
            </div>
        )
    }

    const map_settimana = (el, index) => {
        return (
            <div key={`w${index}`} className={`${classes.week}`}>
                {
                    el.days.map(map_giorno.bind(this, index === 0, index === 5))
                }
            </div>
        )
    }

    const map_calendario = () => {
        return (
            <div className={`pb-2`}>
                <div className={`${classes.week} text-center py-1`}>
                    <span>Lunedì</span>
                    <span>Martedì</span>
                    <span>Mercoledì</span>
                    <span>Giovedì</span>
                    <span>Venerdì</span>
                    <span>Sabato</span>
                    <span>Domenica</span>
                </div>
                {
                    current_month_object ?
                    current_month_object.weeks.map(map_settimana) : 'Nessun calendario presente'
                }
            </div>
        )
    }

    useEffect(() => {
        const first_of_month = new Date(months[current_month] + ' 1 ' + current_year)
        const first_of_month_day = first_of_month.getDay()
        const last_month_last_date = new Date(new Date(months[current_month] + ' 1 ' + current_year).getTime() - 86400000).getDate()
        const next_month_year = current_month === 11 ? current_year + 1 : current_year
        const current_month_last_date = new Date(
            new Date(months[(current_month + 1) % 12] + ' 1 ' + next_month_year)
            .getTime() - 86400000 - new Date(months[current_month] + ' 1 ' + current_year)
            .getTimezoneOffset() * 60000
        ).getDate()
        let current = 1
        let is_current_month = true
        let month = current_month
        let year = current_year
        if (first_of_month_day !== 1) {
            is_current_month = false
            current = last_month_last_date
            month = (current_month + 11) % 12
            year = current_month === 0 ? current_year - 1 : current_year
            for (let i = (first_of_month_day - 1) >= 0 ? first_of_month_day - 1 : 6; i > 1; i--) {
                current--;
            }
        }
        const month_object = {
            weeks: [
                {
                    days: [{}, {}, {}, {}, {}, {}, {}]
                }, {
                    days: [{}, {}, {}, {}, {}, {}, {}]
                }, {
                    days: [{}, {}, {}, {}, {}, {}, {}]
                }, {
                    days: [{}, {}, {}, {}, {}, {}, {}]
                }, {
                    days: [{}, {}, {}, {}, {}, {}, {}]
                }, {
                    days: [{}, {}, {}, {}, {}, {}, {}]
                }
            ]
        }
        for (let i = 0; i < 6; i++) {
            for (let j = 0; j < 7; j++) {
                month_object.weeks[i].days[j] = {
                    date: current,
                    month,
                    year,
                    is_current_month,
                    eventi: []
                }
                current++;
                if (!is_current_month && current > last_month_last_date) {
                    current = 1
                    month = (month + 1) % 12
                    year = month === 0 ? year++ : year
                    is_current_month = true
                }
                if (is_current_month && current > current_month_last_date) {
                    current = 1
                    month = (month + 1) % 12
                    year = month === 0 ? year++ : year
                    is_current_month = false
                }
            }
        }
        const primo = month_object.weeks[0].days[0].year + '-' + (month_object.weeks[0].days[0].month + 1) + '-' + month_object.weeks[0].days[0].date + ' 00:00:00'
        const ultimo = month_object.weeks[5].days[6].year + '-' + (month_object.weeks[5].days[6].month + 1) + '-' + month_object.weeks[5].days[6].date + ' 23:59:59'
        fetch_eventi(primo, ultimo).then(risposta => {
            if (risposta.successo) {
                setEventi(risposta.array)
            }
        })
        setCurrentMonthObject(month_object)
    }, [current_month, reload])

    useEffect(() => {
        if (current_month_object) {
            const month_object = JSON.parse(JSON.stringify(current_month_object))
            for (let i = 0; i < 6; i++) {
                for (let j = 0; j < 7; j++) {
                    const date = month_object.weeks[i].days[j].year + '-' + (month_object.weeks[i].days[j].month < 9 ? '0' : '') +
                    (month_object.weeks[i].days[j].month + 1) + '-' + (month_object.weeks[i].days[j].date < 10 ? '0' : '') +
                    month_object.weeks[i].days[j].date + ' 00:00:00'
                    month_object.weeks[i].days[j].eventi = []
                    for (let k = 0; k < eventi.length; k++) {
                        if (eventi[k].is_scadenza) {
                            const data_scadenza = eventi[k].data_inizio.substr(0, 10) + ' 00:00:00'
                            if (data_scadenza === date) {
                                month_object.weeks[i].days[j].eventi.push({
                                    scadenza: true,
                                    titolo: eventi[k].titolo
                                })
                            }
                        } else {
                            if (date >= eventi[k].data_inizio.substr(0, 10) + ' 00:00:00' && date <= eventi[k].data_fine.substr(0, 10) + ' 00:00:00') {
                                month_object.weeks[i].days[j].eventi.push({
                                    scadenza: false,
                                    titolo: eventi[k].titolo
                                })
                            }
                        }
                    }
                }
            }
            setCurrentMonthObject(month_object)
        }
    }, [eventi])

    return (
        <div className={`${classes.container} max-width`}>
            <div className={`${classes.calendar_container}`}>
                {
                    map_calendario()
                }
            </div>
            <div className={`${classes.form_container} max-width`}>
                <div className={`mb-4`}>
                    <div className={`flex align-center justify-evenly mb-0-5`}>
                        <button onClick={on_precedente} className={`${classes.arrow_buttons} size-semi-regular px-2`}>
                            <i className="fa-solid fa-chevron-left"></i>
                        </button>
                        <div className={`size-big text-center`}>{mesi[current_month]}</div>
                        <button onClick={on_successivo} className={`${classes.arrow_buttons} size-semi-regular px-2`}>
                            <i className="fa-solid fa-chevron-right"></i>
                        </button>
                    </div>
                    <div className={`text-center`}>{current_year}</div>
                </div>
                <hr />
                <div className={`mt-3 px-3`}>
                    <label htmlFor={`titolo`}>Titolo:</label>
                    <input type={`text`} id={`titolo`} className={`${classes.input} mb-2 mt-0-5`} />
                    <label htmlFor={`inizio`}>Dal:</label>
                    <input type={`datetime-local`} id={`inizio`} className={`${classes.input} mb-2 mt-0-5`}/>
                    <label htmlFor={`fine`}>Al:</label>
                    <input type={`datetime-local`} id={`fine`} className={`${classes.input} mb-2 mt-0-5`}/>
                    {
                        amministratore ?
                        <label htmlFor={`scadenza`} className={`relative pl-2 pointer`}>
                            <input type={`checkbox`} id={`scadenza`} className={`${classes.checkbox}`}/> Scadenza
                        </label> : []
                    }
                    <button onClick={on_aggiungi_evento} className={`btn btn-wide btn-primary max-width mt-3`}>Aggiungi evento</button>
                </div>
            </div>
        </div>
    )
}

export default PaginaCalendario