import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { URL, FILES_URL } from "../utils/utils"
import classes from "../stili/PaginaAccount.module.css"
import user_img from "../imgs/user.jpg"

const PaginaAccount = () => {

    const user_id = Number(window.localStorage.getItem('user_id'))
    const user_token = window.localStorage.getItem('user_token')
    const user_amministratore = Number(window.localStorage.getItem('user_amministratore'))

    const [utenti, setUtenti] = useState([])
    const [amministratori, setAmministratori] = useState([])
    const [reload, setReload] = useState(false)
    const [immagine, setImmagine] = useState(null)

    const navigate = useNavigate()

    const fetch_account = async () => {
        const data = new FormData()
        data.append('id', user_id)
        data.append('token', user_token)
        return await fetch(
            URL + '/account.php', {
                method: 'POST',
                body:  data
            }
        ).then(res => {
            return res.json()
        })
    }
    
    const fetch_modifica_account = async (id, nuovo_valore) => {
        const data = new FormData()
        data.append('id', user_id)
        data.append('token', user_token)
        data.append('id_utente', id)
        data.append('nuovo_valore', nuovo_valore)
        return await fetch(
            URL + '/modifica_tipo_account.php', {
                method: 'POST',
                body:  data
            }
        ).then(res => {
            return res.json()
        })
    }

    const on_salva_modifiche = (id) => {
        const nuovo_valore = Number(document.querySelector(`#sel${id}`).value)
        fetch_modifica_account(id, nuovo_valore).then(risposta => {
            if (risposta.successo) {
                setReload(!reload)
            }
        })
    }

    const on_tipo_utente_change = (index, e) => {
        const nuovo_valore = Number(e.target.value)
        const vecchio_valore = utenti[index].tipo_utente
        const copia_utenti = [...utenti]
        if (nuovo_valore !== vecchio_valore) {
            copia_utenti[index].modifica = 1
        } else {
            copia_utenti[index].modifica = 0
        }
        setUtenti(copia_utenti)
    }

    const map_utenti = (el, index) => {
        return (
            <li key={el.id} className={`${classes.list_element} mt-1 align-center`}>
                <div className={`${classes.image_container}`}>
                    <img src={el.immagine ? `${FILES_URL}${el.immagine}` : user_img} alt={`profilo`}
                    className={`${classes.user_image}`} />
                </div>
                <div>
                    {
                        el.usa_soprannome ?
                        el.soprannome : `${el.nome} ${el.cognome}`
                    }
                </div>
                <select id={`sel${el.id}`} defaultValue={el.tipo_utente} className={`${classes.input} pointer`}
                onChange={on_tipo_utente_change.bind(this, index)}>
                    <option value={0}>Ospite</option>
                    <option value={1}>Membro Temporaneo</option>
                    <option value={2}>Membro</option>
                    <option value={3}>Amministratore</option>
                </select>
                {
                    el.modifica ?
                    <button onClick={on_salva_modifiche.bind(this, el.id)} className={`btn btn-primary btn-small size-small`}>Salva</button> : []
                }
            </li>
        )
    }

    const map_amministratori = (el) => {
        return (
            <li key={el.id} className={`${classes.list_element} mt-1 align-center`}>
                <div className={`${classes.image_container}`}>
                    <img src={el.immagine ? `${FILES_URL}${el.immagine}` : user_img} alt={`profilo`}
                    className={`${classes.user_image}`} />
                </div>
                <div>
                    {
                        el.usa_soprannome ?
                        el.soprannome : `${el.nome} ${el.cognome}`
                    }
                </div>
                <div className={`size-small`}>Amministratore</div>
            </li>
        )
    }

    useEffect(() => {
        if (!user_amministratore) {
            navigate('modifica')
        } else {
            fetch_account().then(risposta => {
                if (risposta.successo) {
                    setImmagine(risposta.array.filter(e => e.id === user_id)[0].immagine)
                    setAmministratori(risposta.array.filter(e => e.id !== user_id && e.tipo_utente === 3))
                    setUtenti(risposta.array.filter(e => e.tipo_utente < 3))
                }
            })
        }
    }, [reload, user_amministratore])

    return (
        user_amministratore ?
        <div className={`max-width ${classes.container} ${classes.wrapper} py-2`}>
            <div className={`${classes.accounts_container}`}>
                <div className={`size-big mb-2 ${classes.left_padding}`}>Account</div>
                <div className={`${classes.left_padding}`}>
                    <ul className={`${classes.list_container}`}>
                        <li className={`${classes.list_element} align-center`}>
                            <div className={`${classes.image_container}`}>
                                <img src={immagine ? `${FILES_URL}${immagine}` : user_img} alt={`profilo`}
                                className={`${classes.user_image}`} />
                            </div>
                            <div>Tu</div>
                            <Link to={`modifica`}>
                                <button className={`btn btn-primary max-width size-small`}>
                                    Modifica
                                </button>
                            </Link>
                        </li>
                        {
                            utenti.map(map_utenti)
                        }
                        {
                            amministratori.map(map_amministratori)
                        }
                    </ul>
                </div>
            </div>
            <div className={`${classes.new_accounts_container}`}>
                <div className={`text-center`}>
                    <Link to={`nuovi`}>
                        <img src={user_img} className={`${classes.new_accounts_image}`} alt={`profilo`}/>
                        <br />
                        <button className={`btn btn-big btn-primary`}>
                            Nuovi Account
                        </button>
                    </Link>
                </div>
            </div>
        </div> : []
    )
}

export default PaginaAccount