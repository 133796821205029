import { useEffect, useState } from "react"
import { URL } from "../utils/utils"
import classes from "../stili/PaginaNuoviAccount.module.css"

const PaginaNuoviAccount = (props) => {

    const user_id = window.localStorage.getItem('user_id')
    const user_token = window.localStorage.getItem('user_token')

    const [nuovi_utenti, setNuoviUtenti] = useState([])
    const [reload, setReload] = useState(false)

    const fetch_nuovi_utenti = async () => {
        const data = new FormData()
        data.append('id', user_id)
        data.append('token', user_token)
        return await fetch(
            URL + '/nuovi_utenti.php', {
                method: 'POST',
                body: data
            }
        ).then(res => {
            return res.json()
        })
    }

    const fetch_accetta_utente = async (id_nuovo_utente) => {
        const data = new FormData()
        data.append('id', user_id)
        data.append('token', user_token)
        data.append('nuovo_id', id_nuovo_utente)
        return await fetch(
            URL + '/accetta_utente.php', {
                method: 'POST',
                body: data
            }
        ).then(res => {
            return res.json()
        })
    }

    const fetch_rifiuta_utente = async (id_nuovo_utente) => {
        const data = new FormData()
        data.append('id', user_id)
        data.append('token', user_token)
        data.append('nuovo_id', id_nuovo_utente)
        return await fetch(
            URL + '/rifiuta_utente.php', {
                method: 'POST',
                body: data
            }
        ).then(res => {
            return res.json()
        })
    }

    const on_accetta = (id) => {
        fetch_accetta_utente(id).then(risposta => {
            if (risposta.successo) {
                setReload(!reload)
                props.reload()
            } else {
                window.localStorage.clear()
                window.location.replace('/')
            }
        })
    }
    
    const on_rifiuta = (id) => {
        fetch_rifiuta_utente(id).then(risposta => {
            if (risposta.successo) {
                setReload(!reload)
                props.reload()
            } else {
                window.localStorage.clear()
                window.location.replace('/')
            }
        })
    }

    const map_nuovi_utenti = (el) => {
        return (
            <li key={el.id} className={`${classes.list_element} mb-2`}>
                <div>{el.nome} {el.cognome}</div>
                <div className={`mt-1`}>
                    <button onClick={on_rifiuta.bind(this, el.id)} className={`btn btn-secondary btn-regular ${classes.button}`}>Rifiuta</button>
                    <button onClick={on_accetta.bind(this, el.id)} className={`btn btn-primary btn-regular ${classes.button} ${classes.accetta}`}>Accetta</button>
                </div>
            </li>
        )
    }

    useEffect(() => {
        fetch_nuovi_utenti().then(risposta => {
            if (risposta.successo) {
                setNuoviUtenti(risposta.array)
            } else {
                window.localStorage.clear()
                window.location.replace('/')
            }
        })
    }, [reload])

    return (
        <div className={`${classes.container}`}>
            <div className={`size-big`}>Nuovi Account</div>
            <div className={`mt-3 pl-2`}>
                {
                    nuovi_utenti.length > 0 ?
                    <ul>
                        {
                            nuovi_utenti.map(map_nuovi_utenti)
                        }
                    </ul> : 'Non ci sono nuovi utenti'
                }
            </div>
        </div>
    )
}

export default PaginaNuoviAccount