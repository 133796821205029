import { useEffect, useState } from "react"
import { URL } from "../utils/utils"
import { useNavigate, useParams } from "react-router-dom"
import classes from "../stili/PaginaModificaLuogo.module.css"
import mountain from "../imgs/mountain.jpg"

const PaginaModificaLuogo = () => {

    const user_id = window.localStorage.getItem('user_id')
    const user_token = window.localStorage.getItem('user_token')

    const [dettagli_luogo, setDettagliLuogo] = useState({})

    const navigate = useNavigate()

    const params = useParams()
    const id_luogo = params.idLuogo

    const fetch_dettagli_luogo = async () => {
        const data = new FormData()
        data.append('id', user_id)
        data.append('token', user_token)
        data.append('id_luogo', id_luogo)
        return await fetch(
            URL + '/dettagli_luogo.php', {
                method: 'POST',
                body: data
            }
        ).then(res => {
            return res.json()
        })
    }

    const fetch_salva_luogo = async (nome, descrizione, link_maps, link_percorso, link_parcheggio) => {
        const data = new FormData()
        data.append('id', user_id)
        data.append('token', user_token)
        data.append('nome', nome)
        data.append('descrizione', descrizione)
        if (link_maps) data.append('link_maps', link_maps)
        if (link_percorso) data.append('link_percorso', link_percorso)
        if (link_parcheggio) data.append('link_parcheggio', link_parcheggio)
        return await fetch(
            URL + '/aggiungi_luogo.php', {
                method: 'POST',
                body: data
            }
        ).then(res => {
            return res.json()
        })
    }

    const fetch_modifica_luogo = async (nome, descrizione, link_maps, link_percorso, link_parcheggio) => {
        const data = new FormData()
        data.append('id', user_id)
        data.append('token', user_token)
        data.append('nome', nome)
        data.append('descrizione', descrizione)
        data.append('id_luogo', id_luogo)
        if (link_maps) data.append('link_maps', link_maps)
        if (link_percorso) data.append('link_percorso', link_percorso)
        if (link_parcheggio) data.append('link_parcheggio', link_parcheggio)
        return await fetch(
            URL + '/modifica_luogo.php', {
                method: 'POST',
                body: data
            }
        ).then(res => {
            return res.json()
        })
    }

    const on_salva = () => {
        const nome = document.querySelector('#nome').value
        const descrizione = document.querySelector('#descrizione').value
        const link_maps = document.querySelector('#link_maps').value
        const link_percorso = document.querySelector('#link_percorso').value
        const link_parcheggio = document.querySelector('#link_parcheggio').value
        if (nome && descrizione) {
            if (id_luogo) {
                fetch_modifica_luogo(nome, descrizione, link_maps, link_percorso, link_parcheggio).then(risposta => {
                    if (risposta.successo) {
                        navigate(`/luoghi/visualizza/${id_luogo}`)
                    }
                })
            } else {
                fetch_salva_luogo(nome, descrizione, link_maps, link_percorso, link_parcheggio).then(risposta => {
                    if (risposta.successo) {
                        navigate('/luoghi')
                    }
                })
            }
        }
    }

    const on_annulla = () => {
        if (id_luogo) {
            navigate(`/luoghi/visualizza/${id_luogo}`)
        } else {
            navigate('/luoghi')
        }
    }

    useEffect(() => {
        if (id_luogo) {
            fetch_dettagli_luogo().then(risposta => {
                if (risposta.successo) {
                    setDettagliLuogo(risposta)
                }
            })
        }
    }, [])

    return (
        <div className={`${classes.container} max-width`}>
            <div className={`${classes.header}`}>
                <div className={`flex align-center`}>
                    <img src={mountain} className={`${classes.image}`} alt={`montagna`}/>
                    <div className={`ml-1`}>
                        <input type={`text`} placeholder={`Nome luogo`} className={`${classes.input} ${classes.title_input} size-bigger`}
                        id={`nome`} defaultValue={dettagli_luogo.nome}/>
                    </div>
                </div>
                <div>
                    <button onClick={on_salva} className={`btn btn-primary btn-big ${classes.button}`}>Salva</button>
                    <button onClick={on_annulla} className={`btn btn-secondary btn-big ${classes.button} ml-4`}>Annulla</button>
                </div>
            </div>
            <div className={`${classes.details_container}`}>
                <div className={`mt-2`}>
                    <label htmlFor={`descrizione`} className={`size-semi-regular`}>Descrizione percorso:</label>
                    <div className={`mt-1 ${classes.descrizione}`}>
                        <textarea id={`descrizione`} defaultValue={dettagli_luogo.descrizione_percorso}
                        className={`${classes.input} ${classes.textarea} max-width`} rows={3}></textarea>
                    </div>
                </div>
                <div className={`mt-3`}>
                    <table className={`${classes.table}`}>
                        <thead>
                            <tr className={`text-center`}>
                                <td className={`bg-primary ${classes.table_heads}`}>
                                    <label htmlFor={`link_maps`}>Link Maps</label>
                                </td>
                                <td className={`bg-primary ${classes.table_heads}`}>
                                    <label htmlFor={`link_percorso`}>Link Percorso</label>
                                </td>
                                <td className={`bg-primary ${classes.table_heads}`}>
                                    <label htmlFor={`link_parcheggio`}>Link Parcheggio</label>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className={`${classes.table_bodys}`}>
                                    <input type={`text`} id={`link_maps`} defaultValue={dettagli_luogo.link_maps} className={`${classes.input}`}/>
                                </td>
                                <td className={`${classes.table_bodys}`}>
                                    <input type={`text`} id={`link_percorso`} defaultValue={dettagli_luogo.link_percorso} className={`${classes.input}`}/>
                                </td>
                                <td className={`${classes.table_bodys}`}>
                                    <input type={`text`} id={`link_parcheggio`} defaultValue={dettagli_luogo.link_parcheggio} className={`${classes.input}`}/>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default PaginaModificaLuogo