import { useEffect, useRef, useState } from "react"
import { URL } from "../utils/utils"
import classes from "../stili/PaginaLogin.module.css"

const PaginaLogin = (props) => {

    const alphabet = 'qwerty$uiopasd?fghjklzx%cvbnm-12345(67890_QWERTYUI+OPAASD&FGHJ!KLZXC)VBNM'

    const [register, setRegister] = useState(false)
    const [nuovo_utente, setNuovoUtente] = useState(false)
    const [alerts, setAlerts] = useState(new Map())
    const [update_alerts, setUpdateAlerts] = useState(true)
    const alerts_list = useRef(new Map())

    const fetch_login = async (username, password) => {
        const data = new FormData()
        data.append('username', username)
        data.append('password', password)
        try {
            return await fetch(
                URL + '/login.php', {
                    method: "POST",
                    body: data
                }
            ).then(res => {
                return res.json()
            })
        } catch (error) {
            return error
        }
    }

    const fetch_register = async (nome, cognome, username, password) => {
        const data = new FormData()
        data.append('nome', nome)
        data.append('cognome', cognome)
        data.append('username', username)
        data.append('password', password)
        try {
            return await fetch(
                URL + '/register.php', {
                    method: "POST",
                    body: data
                }
            ).then(res => {
                return res.json()
            })
        } catch (error) {
            return error
        }
    }

    const addAlert = (testo, tipo, remove = false, remove_key) => {
        if (remove && alerts_list.current.has(remove_key)) {
            clearTimeout(alerts_list.current.get(remove_key).timeout)
            alerts_list.current.delete(remove_key)
        }
        let key = ''
        for (let i = 0; i < 20; i++) {
            const rndm = Math.random() * alphabet.length
            key += alphabet.charAt(rndm)
        }
        const timeout = setTimeout(() => {
            alerts_list.current.set(key, {...alerts_list.current.get(key), fade: true})
            setUpdateAlerts(update_alerts => !update_alerts)
            setTimeout(() => {
                alerts_list.current.delete(key)
                setUpdateAlerts(update_alerts => !update_alerts)
            }, 500)
        }, 2500)
        alerts_list.current.set(key, {
            testo,
            tipo,
            fade: false,
            timeout
        })
        setUpdateAlerts(update_alerts => !update_alerts)
        return key
    }

    const on_register = () => {
        const nome = document.querySelector('#nome').value
        const cognome = document.querySelector('#cognome').value
        const username = document.querySelector('#username').value
        const password = document.querySelector('#password').value
        const conferma_password = document.querySelector('#conferma_password').value
        if (nome && cognome && username && password && conferma_password) {
            if (password === conferma_password) {
                document.querySelector('#nome').value = ''
                document.querySelector('#cognome').value = ''
                document.querySelector('#username').value = ''
                document.querySelector('#password').value = ''
                document.querySelector('#conferma_password').value = ''
                fetch_register(nome, cognome, username, password).then(risposta => {
                    if (risposta.successo) {
                        setRegister(false)
                    }
                })
            }
        }
    }

    const on_submit = () => {
        const username = document.querySelector('#username').value
        const password = document.querySelector('#password').value
        if (username && password) {
            fetch_login(username, password).then(risposta => {
                if (risposta.successo) {
                    if (risposta.nuovo_utente) {
                        setNuovoUtente(true)
                    } else {
                        const token = risposta.token
                        const id = Number(risposta.id)
                        const amministratore = Number(risposta.amministratore)
    
                        window.localStorage.setItem('user_token', token)
                        window.localStorage.setItem('user_id', id)
                        window.localStorage.setItem('user_amministratore', amministratore)
    
                        props.setToken(token)
                    }
                } else {
                    addAlert(risposta.testo, 3)
                }
            })
        }
        document.querySelector('#password').value = ''
    }

    const mostra_register = (val) => {
        document.querySelector('form').reset()
        setRegister(val === true)
    }

    const nascondi_nuovo_utente = () => {
        setNuovoUtente(false)
    }

    useEffect(() => {
        setAlerts(alerts_list.current)
    }, [update_alerts])

    const map_alerts = (el, index) => {
        return (
            <div key={index} className={`${el.tipo === 1 ? classes.alert_warning : el.tipo === 2 ? classes.alert_success : classes.alert_error} ${el.fade ? classes.alert_fade : []} ${classes.alert} mx-auto`}>
                {el.testo}
            </div>
        )
    }

    return (
        <div className={`${classes.container}`}>
            {
                nuovo_utente ?
                <div className={`text-center ${classes.wrapper} mx-auto color-primary`}>
                    <div className={`mt-3`}>
                        Il tuo utente &egrave; stato da poco creato, deve ancora essere verificato da un amministratore
                    </div>
                    <button onClick={nascondi_nuovo_utente} className={`btn btn-wide btn-primary mt-3`}>Torna indietro</button>
                </div> :
                register ? 
                <div className={`text-center ${classes.wrapper} mx-auto color-primary`}>
                    <div className={`py-3 size-big`}>
                        Nuovo account
                    </div>
                    <form>
                        <div className={`py-2 ${classes.form} text-start`}>
                            <label htmlFor={`nome`}>Nome:</label>
                            <input type={`text`} id={`nome`} className={`${classes.input}`} />
                            <label htmlFor={`cognome`}>Cognome:</label>
                            <input type={`text`} id={`cognome`} className={`${classes.input}`} />
                            <label htmlFor={`username`}>Username:</label>
                            <input type={`text`} id={`username`} className={`${classes.input}`} />
                            <label htmlFor={`password`}>Password:</label>
                            <input type={`password`} id={`password`} className={`${classes.input}`} />
                            <label htmlFor={`conferma_password`}>Conferma password:</label>
                            <input type={`password`} id={`conferma_password`} className={`${classes.input}`} />
                        </div>
                    </form>
                    <div className={`${classes.buttons_container} mt-3`}>
                        <button onClick={mostra_register.bind(this, false)} className={`btn btn-wide btn-secondary`}>Accedi</button>
                        <button onClick={on_register} className={`btn btn-wide btn-primary`}>Conferma</button>
                    </div>
                </div> :
                <div className={`text-center ${classes.wrapper} mx-auto color-primary`}>
                    <div className={`${classes.alerts} max-width`}>
                        {
                            Array.from(alerts, ([key, value]) => (value)).reverse().map(map_alerts)
                        }
                    </div>
                    <div className={`py-3 size-big`}>
                        Login
                    </div>
                    <form>
                        <div className={`py-2 ${classes.form} text-start`}>
                            <label htmlFor={`username`}>Username:</label>
                            <input type={`text`} id={`username`} className={`${classes.input}`} />
                            <label htmlFor={`password`}>Password:</label>
                            <input type={`password`} id={`password`} className={`${classes.input}`} />
                        </div>
                    </form>
                    <div className={`${classes.buttons_container} mt-3`}>
                        <button onClick={mostra_register.bind(this, true)} className={`btn btn-wide btn-secondary`}>Registrati</button>
                        <button onClick={on_submit} className={`btn btn-wide btn-primary`}>Accedi</button>
                    </div>
                </div>
            }
        </div>
    )
}

export default PaginaLogin