import { useEffect, useState } from "react"
import { URL } from "../utils/utils"
import classes from "../stili/PaginaCestino.module.css"
import project from "../imgs/project.jpg"

const PaginaCestino = (props) => {

    const user_id = window.localStorage.getItem('user_id')
    const user_token = window.localStorage.getItem('user_token')

    const [inchieste_selezionate, setInchiesteSelezionate] = useState([])
    const [inchieste, setInchieste] = useState(false)
    const [reload, setReload] = useState(false)
    const [inchieste_trovate, setInchiesteTrovate] = useState([])
    const [cerca, setCerca] = useState(false)

    const fetch_inchieste = async () => {
        const data = new FormData()
        data.append('id', user_id)
        data.append('token', user_token)
        return await fetch(
            URL + '/cestino.php', {
                method: 'POST',
                body: data
            }
        ).then(res => {
            return res.json()
        })
    }

    const fetch_ripristina_inchieste = async () => {
        const data = new FormData()
        data.append('id', user_id)
        data.append('token', user_token)
        data.append('inchieste', JSON.stringify(inchieste_selezionate))
        return await fetch(
            URL + '/ripristina.php', {
                method: 'POST',
                body: data
            }
        ).then(res => {
            return res.json()
        })
    }

    const fetch_elimina_inchieste = async () => {
        const data = new FormData()
        data.append('id', user_id)
        data.append('token', user_token)
        data.append('inchieste', JSON.stringify(inchieste_selezionate))
        return await fetch(
            URL + '/elimina_inchieste.php', {
                method: 'POST',
                body: data
            }
        ).then(res => {
            return res.json()
        })
    }

    const on_inchiesta_selezionata = (id, e) => {
        const index = inchieste_selezionate.indexOf(id)
        if (index >= 0) {
            if (!e.target.checked) {
                const copy_inchieste_selezionate = [...inchieste_selezionate]
                copy_inchieste_selezionate.splice(index, 1)
                setInchiesteSelezionate(copy_inchieste_selezionate)
            }
        } else {
            if (e.target.checked) {
                const copy_inchieste_selezionate = [...inchieste_selezionate]
                copy_inchieste_selezionate.push(id)
                setInchiesteSelezionate(copy_inchieste_selezionate)
            }
        }
    }

    const on_elimina = () => {
        if (inchieste_selezionate.length > 0) {
            let alert_testo = 'Sto eliminando l\'inchiesta selezionata...'
            if (inchieste_selezionate.length > 1) alert_testo = 'Sto eliminando le inchieste selezionate...'
            const key = props.add_alert(alert_testo, 1)
            fetch_elimina_inchieste().then(risposta => {
                if (risposta.successo) {
                    let alert_testo_risposta = 'Inchiesta eliminata correttamente'
                    if (inchieste_selezionate.length > 1) alert_testo = 'Inchieste eliminate correttamente'
                    props.add_alert(alert_testo_risposta, 2, true, key)
                    setInchiesteSelezionate([])
                    setReload(!reload)
                }
            })
        }
    }

    const on_ripristina = () => {
        if (inchieste_selezionate.length > 0) {
            let alert_testo = 'Sto ripristinando l\'inchiesta selezionata...'
            if (inchieste_selezionate.length > 1) alert_testo = 'Sto ripristinando le inchieste selezionate...'
            const key = props.add_alert(alert_testo, 1)
            fetch_ripristina_inchieste().then(risposta => {
                if (risposta.successo) {
                    let alert_testo_risposta = 'Inchiesta ripristinata correttamente'
                    if (inchieste_selezionate.length > 1) alert_testo = 'Inchieste ripristinate correttamente'
                    props.add_alert(alert_testo_risposta, 2, true, key)
                    setInchiesteSelezionate([])
                    setReload(!reload)
                }
            })
        }
    }

    const on_cerca_change = (e) => {
        const ricerca = e.target.value.trim()
        setCerca(ricerca.length > 0)
        if (ricerca.length > 0) {
            const regex = new RegExp(`.*${ricerca}.*`, 'i')
            setInchiesteTrovate(inchieste.filter(el => regex.test(el.titolo)))
        } else {
            setInchiesteTrovate([])
        }
    }

    const map_inchieste = (el) => {
        return (
            <li key={el.id} className={`relative`}>
                {
                    el.is_proprietario ?
                    <input type={`checkbox`} id={`chk${el.id}`} className={`${classes.card_checkbox}`}
                    onChange={on_inchiesta_selezionata.bind(this, el.id)} /> : []
                }
                <label htmlFor={`chk${el.id}`}>
                    <div className={`${classes.card} ${el.is_proprietario ? 'pointer' : []} pt-1`}>
                        <div className={`${classes.card_image_container} text-center py-0-5`}>
                            <img src={project} className={`${classes.card_image}`} alt={`inchiesta`} />
                        </div>
                        <div className={`${classes.card_title_container} ${el.is_proprietario ? classes.proprietario : classes.no_proprietario} py-0-3 text-center px-2`}>
                            {el.titolo}
                        </div>
                    </div>
                </label>
            </li>
        )
    }

    useEffect(() => {
        fetch_inchieste().then(risposta => {
            if (risposta.successo) {
                setInchieste(risposta.array)
            }
        })
    }, [reload])

    return (
        <div className={`max-width ${classes.container} py-2`}>
            <div className={`${classes.search_bar_container}`}>
                <input type={`text`} autoComplete={`off`} placeholder={`Cerca`} className={`${classes.input}`} onChange={on_cerca_change} />
            </div>
            <div className={`${classes.wrapper}`}>
                <div className={`p-3`}>
                    {
                        inchieste.length > 0 ?
                        cerca ?
                        inchieste_trovate.length > 0 ?
                        <ul className={`${classes.cards_container} mx-auto`}>
                            {inchieste_trovate.map(map_inchieste)}
                        </ul> :
                        'Nessuna inchiesta trovata' :
                        <ul className={`${classes.cards_container} mx-auto`}>
                            {inchieste.map(map_inchieste)}
                        </ul> :
                        'Nessuna inchiesta al momento presente'
                    }
                </div>
                <div className={`pt-3 mb-3`}>
                    <div className={`text-center`}>
                        <button className={`btn ${inchieste_selezionate.length > 0 ? 'btn-primary' : 'btn-disabled'} btn-wide`} onClick={on_ripristina}>
                            {
                                inchieste_selezionate.length > 1 ? 'Ripristina inchieste' : 'Ripristina inchiesta'
                            }
                        </button>
                    </div>
                    <div className={`text-center mt-3`}>
                        <button className={`btn ${inchieste_selezionate.length > 0 ? 'btn-primary' : 'btn-disabled'} btn-wide`} onClick={on_elimina}>
                            {
                                inchieste_selezionate.length > 1 ? 'Elimina inchieste' : 'Elimina inchiesta'
                            }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaginaCestino